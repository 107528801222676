
























































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import Company from '@/models/Company';
import {NavigationSubItemInterface} from '@/interfaces/NavigationSubItem.interface';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import {Permission} from '@/misc/enums/permission.enum';

const AuthStore = namespace('auth');
const CompanyStore = namespace('company');

/**
 * Profile component for navigation bar.
 */
@Component({
  components: {
    ImportComponent: () => import(
        '@/components/shared/Import.component.vue'),
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
  },
})
export default class NavigationProfileComponent extends Vue {

  private options: NavigationSubItemInterface[] = [];
  private userConfig: UserInitialsConfig = {
    showFullName: false,
    big: true,
    showTooltip: false,
  };

  private showImportModal: boolean = false;

  @AuthStore.Mutation('clearLogin')
  private clearLogin!: () => void;
  @CompanyStore.Mutation('reset')
  private resetCompanyStore!: () => void;
  @CompanyStore.Getter('activeCompany')
  public _company!: Company;

  @AuthStore.Getter('user')
  private _user!: User;

  constructor() {
    super();
  }

  private mounted() {
    this.options = [{
      title: 'NAVIGATION.DROPDOWN.SETTINGS',
      routerName: 'settings',
      permission: Permission.COMPANY_READ_OWN,
      icon: '',
    }, {
      title: 'NAVIGATION.DROPDOWN.LOGOUT',
      routerName: 'logout',
      permission: Permission.USER_READ_OWN,
      icon: '',
    }].filter((item) => this.$userRoleHandler.hasPermission(item.permission));
  }

  get user(): User {
    return this._user;
  }

  /**
   * On click handler that activates a route
   * @param item
   */
  public onNavItemClick(item: any) {
    if (item.routerName === 'logout') {
      this.handleLogout();
      this.$router.push({name: 'login'});
    } else if (item.routerName === 'settings') {
      this.$router.push({name: 'settings'});
    }
  }

  /**
   * Handles logout and clears all store values
   */
  private handleLogout() {
    this.clearLogin();
    this.resetCompanyStore();
  }

  public closeImportModal() {
    this.showImportModal = false;
  }
}
